import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ManyImage = ({ images }) => {
    const [img, setImage] = useState('');

    useEffect(() => {
        const firstImage = images?.[0]?.image;
        setImage(firstImage ? `https://erhem.templateapi.xyz/template/uploads/${firstImage}`
            : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn0hr3Zv9xFM6-psSAqxrjsdlB-tfcYb_Juw&s');
    }, [images]);

    return (
        <div>
            <img className='w-[40vw] h-[40vh] max-md:w-full border-2' src={img} alt="" />
            <Swiper
                spaceBetween={10}
                slidesPerView={window.innerWidth > 720 ? 4 : 3}
                navigation={true}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                onSlideChange={() => console.log('slide change')}
                className="w-[40vw] max-md:w-full px-10 max-md:p-0"
            >
                {images ? images.map((e) => (
                    <SwiperSlide onClick={() => setImage('https://erhem.templateapi.xyz/template/uploads/' + e.image)} className=''>
                        <img className='w-[10vw] h-[10vh] max-md:w-[100vw] max-md:h-[10vh]' src={'https://erhem.templateapi.xyz/template/uploads/' + e.image} alt="" />
                        {
                            'https://erhem.templateapi.xyz/template/uploads/' + e.image === img ? null :
                                <div className='w-[10vw] max-md:w-[30vw] h-[10vh] bg-black absolute bottom-0 opacity-40'></div>
                        }
                    </SwiperSlide>
                )) : ''}
            </Swiper>
        </div>
    )
}

export default ManyImage
