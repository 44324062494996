import React from 'react'
import PricePlanCard from '../../Utills/PricePlanCard'

const PricePlan = ({ prices }) => {

    const datas = [
        {
            colorfrom: '#22BDFF',
            colorto: '#91FFDE',
        },
        {
            colorfrom: '#22BDFF',
            colorto: '#5D6DFF',
        },
        {
            colorfrom: '#22BDFF',
            colorto: '#FFEF5D',
        },
        {
            colorfrom: '#8380FE',
            colorto: '#FF4848',
        },
    ]

    // alert(prices.length)

    return (
        <div className='flex justify-between items-start gap-10 w-[90vw] max-md:overflow-x-scroll max-md:w-[90vw] max-md:p-5'>
            {prices.map((e, index) => (
                <PricePlanCard {...e}
                    colorfrom={datas[index].colorfrom} colorto={datas[index].colorto}
                // isBest={e.isBest} price={e.price} mount={e.mount}
                // descriptions={e.descriptions} colorfrom={e.colorfrom} colorto={e.colorto}
                />
            ))}
        </div>
    )
}

export default PricePlan
