import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import axios from 'axios';
import { RiCheckboxBlankLine } from "react-icons/ri";
import { IoCheckboxOutline } from "react-icons/io5";
import dayjs from 'dayjs';
import { IoClose } from "react-icons/io5";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function UseActivePlanModal({ open, handleClose, img, productId }) {
    const user = JSON.parse(window.localStorage.getItem('template_user'));
    const [plans, setPlans] = React.useState([]);
    const [plan, setPlan] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        if (isLoading) {
            // alert('asd')
            axios.get('https://erhem.templateapi.xyz/template/acitveplan?isUsed=false')
                .then((e) => {
                    setPlans(e.data.data);
                })
                .catch((e) => console.log(e))
                .finally(() => setIsLoading(false))
        }
    }, [isLoading])

    if (isLoading) {
        return <div className="loader"></div>
    }

    const activeplan = () => {
        axios.post('https://erhem.templateapi.xyz/template/acitveplan/checkout/' + plan._id, {
            userId: user._id,
            planId: plan.planId,
            productId: productId,
            productImg: img,
            // title: title,
            // mount: date,
            expireDate: dayjs().add(plan.mount, 'month'),
        }).then(() => window.location.reload(false))
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div className="flex mb-10 justify-between w-full items-center">
                            <h2 className='text-2xl font-semibold'>Багц сонгох</h2>
                            <IoClose size={30} className='hover:cursor-pointer' onClick={handleClose} />
                        </div>
                        <div className='flex flex-col gap-5'>
                            {plans.length > 0 ? plans.map((e) => (
                                <div onClick={() => setPlan(e)} className='flex hover:cursor-pointer justify-start items-center border-b-[1px] border-[#d3d3d3] gap-3'>
                                    {plan._id === e._id ? <IoCheckboxOutline color='green' onClick={() => setPlan(e)} size={25} />
                                        : <RiCheckboxBlankLine color='#838383' size={25} />}
                                    <p>{e.title}</p>
                                </div>
                            )) : <p className='font-thin text-sm text-red-600'>Та багц идвэхжүүлнэ үү</p>}
                            <Button onClick={activeplan} style={{ background: '#333' }} variant='contained'>Идвэхжүүлэх</Button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}