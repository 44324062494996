import React, { createContext, useContext, useState, useEffect } from "react";
const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        const storedCart = localStorage.getItem("cart_temp");
        return storedCart ? JSON.parse(storedCart) : [];
    });

    useEffect(() => {
        const storedCart = localStorage.getItem("cart_temp");
        if (storedCart) {
            setCart(JSON.parse(storedCart));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("cart_temp", JSON.stringify(cart));
    }, [cart]);

    const addCardToCart = (newCard) => {
        setCart((prevCart) => {
            const existingCard = prevCart ? prevCart.find((item) => item.id === newCard.id) : false;
            if (existingCard) {
                return prevCart.map((item) =>
                    item.id === newCard.id
                        ? { ...item }
                        : item
                );
            } else {
                return prevCart ? [...prevCart, newCard] : [newCard];
            }
        });
    };

    const removeFromCart = (id) => {
        setCart((prevCart) => prevCart.filter((item) => item.id !== id));
    };

    return (
        <CartContext.Provider
            value={{ cart, addCardToCart, removeFromCart }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    return useContext(CartContext);
};
