import React, { useState } from 'react';
import PrimaryButton from '../../Utills/Button/PrimaryButton';
import SecondaryButton from '../../Utills/Button/SecondaryButton';
import { Link } from 'react-router-dom';
import NotficationSnackBar from '../../Utills/NotficationSnackBar';
import axios from 'axios';

const SignUpForm = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passagain, setPassAgain] = useState('');
    const [snackbar, setSnackbar] = useState({ isOpen: false, msg: '', color: '' });

    const handleSubmit = () => {
        const signbody = {
            name: name,
            email: email,
            phone: phone,
            password: password,
        }

        if (password !== passagain) {
            setSnackbar({
                isOpen: true,
                msg: 'Нууц үг ижил байх ёстой',
                color: 'error',
            });
            return;
        } else {
            axios.post('https://erhem.templateapi.xyz/template/user/register', signbody)
                .then(() => {
                    setSnackbar({
                        isOpen: true,
                        msg: 'Амжилттай бүртгэлээ',
                        color: 'success',
                    });
                }).then(() => window.location.reload(false));
        }
    };

    const closeSnackbar = () => {
        setSnackbar({ ...snackbar, isOpen: false });
    };

    return (
        <div className='flex flex-col w-[40vw] gap-5 justify-center items-center max-md:w-full max-md:h-screen'>
            <h2 className='text-[22px] font-semibold w-[50%] self-end text-[#22BDFF] max-md:w-[70%]'>Бүртгүүлэх!</h2>
            <div className='w-[50%] self-center flex flex-col gap-5 justify-center items-center max-md:w-[70%]'>
                <div className='w-full'>
                    <p>И-Мэйл:</p>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" className='p-2' placeholder='И-мэйл' />
                </div>
                <div className='w-full'>
                    <p>Нэр:</p>
                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" className='p-2' placeholder='Нэр' />
                </div>
                <div className='w-full'>
                    <p>Утасны дугаар:</p>
                    <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" className='p-2' placeholder='Утасны дугаар' />
                </div>
                <div className='w-full'>
                    <p>Нууц үг:</p>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className='p-2' placeholder='Нууц үг' />
                </div>
                <div className='w-full'>
                    <p>Нууц үг давтах:</p>
                    <input value={passagain} onChange={(e) => setPassAgain(e.target.value)} type="password" className='p-2' placeholder='Нууц үг давтах' />
                </div>
            </div>
            <div className='w-[50%] flex items-end justify-end max-md:w-[70%]'>
                <p className='w-[max-content] self-end border-b-2 border-[#333]'>Нууц үг мартсан</p>
            </div>
            <button className='w-full' onClick={handleSubmit}>
                <PrimaryButton text={'Бүртгүүлэх'} width={window.innerWidth > 720 ? '50%' : '70%'} />
            </button>
            <div className='flex gap-2 justify-center items-center w-[50%] max-md:w-[70%]'>
                <div className="divider w-[30%]"></div>
                <p className='w-[40%] text-center'>Бүртгэлтэй бол</p>
                <div className="divider w-[30%]"></div>
            </div>
            <Link to={'/login'} className='flex self-center items-center justify-center w-full'>
                <SecondaryButton text={'Нэвтрэх'} width={window.innerWidth > 720 ? '50%' : '70%'} />
            </Link>
            {snackbar.isOpen && (
                <NotficationSnackBar
                    msg={snackbar.msg}
                    color={snackbar.color}
                    open={snackbar.isOpen}
                    closeSnackbar={closeSnackbar}
                    onClose={closeSnackbar}
                />
            )}
        </div>
    );
};

export default SignUpForm;
