import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { LuSend } from "react-icons/lu";

const ComingPage = () => {
    const canvasRef = useRef(null);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        class Particle {
            constructor(x, y, distance) {
                this.angle = Math.random() * 2 * Math.PI;
                this.radius = Math.random();
                this.opacity = (Math.random() * 5 + 2) / 10;
                this.distance = (1 / this.opacity) * distance;
                this.speed = this.distance * 0.00003;

                this.position = {
                    x: x + this.distance * Math.cos(this.angle),
                    y: y + this.distance * Math.sin(this.angle),
                };
            }

            draw() {
                ctx.fillStyle = `rgba(255,255,255,${this.opacity})`;
                ctx.beginPath();
                ctx.arc(this.position.x, this.position.y, this.radius, 0, Math.PI * 2, false);
                ctx.fill();
                ctx.closePath();
            }

            update(x, y) {
                this.angle += this.speed;
                this.position = {
                    x: x + this.distance * Math.cos(this.angle),
                    y: y + this.distance * Math.sin(this.angle),
                };
                this.draw();
            }
        }

        class Emitter {
            constructor(x, y) {
                this.position = { x, y };
                this.radius = 30;
                this.count = 3000;
                this.particles = [];

                for (let i = 0; i < this.count; i++) {
                    this.particles.push(new Particle(this.position.x, this.position.y, this.radius));
                }
            }

            draw() {
                ctx.fillStyle = "rgba(0,0,0,1)";
                ctx.beginPath();
                ctx.arc(this.position.x, this.position.y, this.radius, 0, Math.PI * 2, false);
                ctx.fill();
                ctx.closePath();
            }

            update() {
                for (let i = 0; i < this.count; i++) {
                    this.particles[i].update(this.position.x, this.position.y);
                }
                this.draw();
            }
        }

        const emitter = new Emitter(canvas.width / 2, canvas.height / 2);

        const loop = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            emitter.update();
            requestAnimationFrame(loop);
        };

        loop();

        // Cleanup on component unmount
        return () => {
            cancelAnimationFrame(loop);
        };
    }, []);

    const sentFeedBack = () => {
        axios.post('https://erhem.templateapi.xyz/template/msg', {
            phone: 89257078,
            message: msg
        })
            .then(() => window.location.reload(false))
            .catch((e) => console.log(e))
    }

    return <><canvas ref={canvasRef} style={{ display: "block", background: 'black' }}
        className="z-40"
    ></canvas>
        <div className=" z-50 absolute mt-[45vh] w-[20vw] flex flex-col gap-5 items-center max-md:w-screen">
            <p className="text-[white] text-5xl font-bold">Тун удахгүй...</p>
            <p className="text-[#d3d3d3] w-full max-md:w-[80%]
            ">Та урьдчилсан захиалга өгөх, бидэнтэй хамтран ажиллах эсвэл санал хүсэлт байгаа бол: </p>
            <div className="flex bg-black">
                <textarea name="" onChange={(e) => setMsg(e.target.value)}
                    className="bg-black border-[1px] text-white w-full border-[#838383] rounded-none" id=""></textarea>
                <button onClick={sentFeedBack} className="text-black bg-zinc-500 p-3">
                    <LuSend size={30} />
                </button>
            </div>
        </div>
    </>
};

export default ComingPage;
