import React from 'react'
import { IoHeart } from "react-icons/io5";
import { useCart } from '../../Context/CartContext';
import { Link } from 'react-router-dom';

const SavedWeb = () => {
    const { cart, removeFromCart } = useCart();

    const handleDecrement = (id) => {
        removeFromCart(id);
    };

    cart.forEach((e) => {
        console.log(e);
    })

    return (
        <div className='flex flex-wrap justify-between items-center w-full gap-5 max-md:justify-center'>
            {cart.map((e) => (
                <Link to={'/product/' + e.id}>
                    {/* <p className='text-black'>{e.title}</p> */}
                    <img className='w-[25vw] h-[30vh] max-md:w-[40vw] max-md:h-[15vh] border p-1 rounded-2xl' src={e.img} alt="" />
                    <div className='bg-[#ff8f8f] rounded-full p-1 absolute mt-[-50px] ml-[22vw] max-md:ml-[28vw]'>
                        <IoHeart onClick={() => handleDecrement(e.id)} size={30} color='red' />
                    </div>
                </Link>
            ))}
        </div>
    )
}

export default SavedWeb
