import React from 'react'

const PriceCategories = ({ isActive, setIsActive, categories }) => {
    return (
        <div className='flex gap-5 justify-start items-center max-md:w-[90vw] max-md:overflow-x-scroll max-md:p-3'>
            {categories.map((e) => (
                <button onClick={() => setIsActive(e._id)}
                    className={isActive === e._id ? 'text-[#22BDFF] border-b-2 border-[#22BDFF]' : 'text-[#838383]'}>
                    {e.title}
                </button>
            ))}
        </div>
    )
}

export default PriceCategories
