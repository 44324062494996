import React, { useState } from 'react'
import ActivePlanModal from './Modal/ActivePlan'
import dayjs from 'dayjs';

const PricePlanCard = ({ date, descriptions, colorfrom, colorto, price, isBest, _id, title, isAvailablePlan, isUsed }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    return (
        <div className='shadow-center rounded-lg min-h-[80vh] w-[20vw] flex flex-col justify-start items-center max-md:w-[90vw]'>
            {isBest ? <p className='bg-red-600 w-full rounded-t-lg px-3 text-center text-white text-[14px] py-1 font-semibold'>
                Хамгийн их худалдан авалттай багц
            </p> : null}
            <p
                className={`text-[23px] font-semibold ${isBest ? 'rounded-b-md rounded-t-none' : 'rounded-md'} px-14 py-2 text-center w-full`}
                style={{
                    background: `linear-gradient(to right, ${colorfrom}, ${colorto})`,
                    color: '#fff',
                }}>
                {date} сарын багц
            </p>
            <div className='flex flex-col justify-between gap-24 items-center min-h-[70vh] max-md:w-[65vw]'>
                <div className='p-2'>
                    {/* {descriptions.length} */}
                    {descriptions.map((e, idx) => (
                        <div key={idx} className='flex flex-col items-start justify-center gap-1'>
                            <p className='text-[14px] text-[#838383]'>{e.title}</p>
                            {e.descriptions.map((el, subIdx) => (
                                <p key={subIdx} className='text-[#333] text-[15px] w-full font-medium pl-2'>-{el.description}</p>
                            ))}
                            <div className="w-[18vw] mb-5">
                                <div className="divider"></div>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    {isAvailablePlan ? <div className='flex justify-center items-center flex-col'>
                        {isUsed ?
                            <button className='text-[red] rounded-md shadow-center text-[17px] font-medium text-center w-[15vw] max-md:w-full max-md:px-3'>
                                Ашигласан
                            </button> : <button className='text-[green] rounded-md shadow-center text-[17px] font-medium text-center w-[15vw] max-md:w-full max-md:px-3'>
                                Ашиглаагүй
                            </button>}
                    </div>
                        : <button onClick={() => setOpen(true)} className='text-[#22BDFF] mb-5 rounded-md shadow-center text-[17px] font-medium text-center w-[15vw] max-md:w-full max-md:px-3'>
                            {Intl.NumberFormat('en-us').format(price)}₮ / сар
                        </button>}
                </div>
            </div>
            <ActivePlanModal date={date} price={price} id={_id} title={title} open={open} handleClose={handleClose} />
        </div>
    )
}

export default PricePlanCard
