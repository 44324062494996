import React from 'react'
import PrimaryButton from '../../Utills/Button/PrimaryButton'
import { RxExit } from "react-icons/rx";

const UserInfo = ({ userInfo }) => {
    return (
        <div className='flex w-full justify-center items-center gap-5'>
            <img className='w-[150px] h-[150px] rounded-[100%] p-0' src="https://www.svgrepo.com/show/420360/avatar-batman-comics.svg" alt="" />
            <div className='flex flex-col gap-5'>
                <h2 className='text-xl font-semibold'>{userInfo.name}</h2>
                <h2 className='text-sm text-[#838383]'>{userInfo.email}</h2>
                <PrimaryButton bg={'#333'} text={'Мэдээлэл солих'} />
                <div onClick={() => {
                    window.localStorage.removeItem('template_user');
                    window.location.reload(false);
                }} className='border-[1px] border-red-600 px-5 py-1 hover:cursor-pointer text-red-600 rounded-md flex justify-start items-center gap-2'>
                    <RxExit />
                    <p>Гарах</p>
                </div>
            </div>
        </div>
    )
}

export default UserInfo
